<template>
  <div>
    <div class="back">
      <div class="info">
        <h1>
          A free registration service using <span @click="goLetsEncrypt">Let's Encrypt</span>,
          you can register a certificate with a simpler operation
        </h1>
        <div class="button">
          <el-button type="info" v-if="checkLogin()===false" @click="goLogin" plain>Get Started</el-button>
          <el-button type="info" v-if="checkLogin()===true" @click="goUser" plain>Dashboard</el-button>
          <el-button type="info" @click="goDonate" plain>Donate</el-button>
        </div>
      </div>
    </div>
    <div class="acknowledgement">
      <div class="container">
        <el-divider content-position="left" style="width: 80%">ACKNOWLEDGEMENT</el-divider>
        <el-row type="flex">
          <el-col :lg="{span:4,offset:6}" :xs="{span:5,offset:4}">
            <a>Power By</a>
          </el-col>
          <el-col :lg="{span:9,offset:3}" :xs="{span:10,offset:5}">
            <a>Home Background Source</a>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :lg="{span:3,offset:4}" :xs="{span:12,offset:0}">
            <img src="../assets/letsencrypt-logo.png" alt="letsencrypt-logo" @click="goLetsEncrypt"/>
          </el-col>
          <el-col :lg="{span:5,offset:8}" :xs="{span:8,offset:3}">
            <img src="../assets/imgur-logo.png" style="padding-top: 10px" alt="imgur-logo" @click="goImgur"/>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>

const StringUtils = require("../util/StringUtils.js")
export default {
  name: 'Home',
  methods: {
    goLetsEncrypt() {
      window.open("https://letsencrypt.org/")
    },
    goImgur() {
      window.open("https://imgur.com/gallery/OzT9d")
    },
    checkLogin() {
      const user = window.localStorage.getItem("user");
      return StringUtils.isNotBlank(user);
    },
    goDonate(){
      this.$router.push({path: '/donate'});
    },
    goLogin() {
      if (this.$route.path !== '/login') {
        this.$router.push({path: '/login'});
      }
    },
    goUser() {
      if (this.$route.path !== '/dashboard') {
        this.$router.push({path: '/dashboard'});
      }
    },
  }
}
</script>

<style lang="scss">
.back {
  padding: 55px 0;
  text-align: center;
  background: #e1dcd2 url('../assets/home-back.jpg') no-repeat 50%;
  background-size: cover;
}

.info {
  background-color: rgba(254, 254, 254, .7);
  border: 1px solid rgba(254, 254, 254, .85);
  border-radius: 10px;
  padding: 30px;
  width: 60%;
  max-width: 950px;
  margin: 0 auto;

  h1 {
    font-weight: 300;
    font-size: 40px;
    color: #2c3c69;
    line-height: 1.4;
    margin: 0 0 .35em;

    span {
      color: #de8216;
      font-weight: 700;
      cursor: pointer;
    }
  }
}

.button {
  text-align: center;
  margin-top: 30px;

  .el-button {
    padding: 15px 25px;
    font-size: 30px;
    margin: 0 30px;
  }
}

.acknowledgement {
  text-align: center;
  margin: 0 auto;

  .container {
    width: 40%;
    margin: 0 auto;

    .el-divider {
      background-color: #b1b3b9;
    }

    .el-divider__text {
      font-size: 15px;
      font-weight: 700;
    }

    img {
      height: 50px;
      cursor: pointer;
    }

    a {
      font-weight: 700;
      font-size: 20px;
      color: #de8216;
    }

  }

}

@media screen and (max-width: 769px) {
  .info {
    width: 90%;
    padding: 10px;
  }
  .info h1 {
    font-size: 26px;
  }
  .button .el-button {
    font-size: 20px;
    margin: 0 10px;
    padding: 10px 15px;
  }

  .acknowledgement .container {
    width: 90%;
  }

  .acknowledgement .container img {
    height: 40px;
  }

  .acknowledgement .container a {
    font-size: 15px;
  }

}

</style>
