import axios from 'axios';
import ElementUI from 'element-ui';

const service = axios.create({
    // timeout: 5000
});

service.interceptors.response.use(
    response => {
        return response;
    }, error => {
        switch (error.response.status) {
            case 400:
                console.error(error)
                ElementUI.Message({
                    message: error.response.data.errorDescription,
                    type: 'error',
                    duration: 5000
                });
                break;
            case 401:
                ElementUI.Message({
                    message: 'Not logged in yet',
                    type: 'error',
                    duration: 5000
                });
                localStorage.removeItem('user');
                window.location.href = '/#/login'
                break;
            case 403:
                ElementUI.Message({
                    message: 'Forbidden',
                    type: 'error',
                    duration: 5000
                });
                break;
            case 404:
                ElementUI.Message({
                    message: 'Not found',
                    type: 'error',
                    duration: 5000
                });
        }
        if (error.response.status >= 500) {
            ElementUI.Message({
                message: 'Server error',
                type: 'error',
                duration: 5000
            });
        }
        return Promise.reject(error);
    }
);

export default service;
