<template>
  <div style="margin: 20px 0;">
    <el-row type="flex">
      <el-col :lg="{span:3,offset:6}" :xs="{span:13,offset:1}" :sm="{span:15,offset:2}">
        <img src="../assets/logo.png" class="img" alt="logo" @click="goIndex"/>
        <div class="header-title" @click="goIndex">Let's SSL</div>
      </el-col>
      <el-col :lg="{span:2,offset:7}" :xs="{span:6,offset:2}" :sm="{span:6,offset:1}">
        <div class="header-button">
          <el-button type="primary" @click="goLogin" v-if="checkLogin()===false&&isLogin()===false" plain>Login
          </el-button>
          <el-button type="primary" @click="logout" v-if="checkLogin()===true&&isDashboard()===true" plain>Logout
          </el-button>
          <el-button type="primary" @click="goUser" v-if="checkLogin()===true&&isDashboard()===false" plain>Dashboard
          </el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import service from "@/util/request";

const api = require("@/util/api")
const StringUtils = require("../util/StringUtils.js")

export default {
  name: 'Header',
  methods: {
    goLogin() {
      if (this.$route.path !== '/login') {
        this.$router.push({path: '/login'});
      }
    },
    goUser() {
      if (this.$route.path !== '/dashboard') {
        localStorage.removeItem('order');
        this.$router.push({path: '/dashboard'});
      }
    },
    goIndex() {
      window.location.href = '/'
    },
    checkLogin() {
      const user = window.localStorage.getItem("user");
      return StringUtils.isNotBlank(user);
    },
    isDashboard() {
      return this.$route.path === '/dashboard';
    },
    isLogin() {
      return this.$route.path === '/login';
    },
    logout() {
      service.get(api.logout).then(() => {
        this.$message.success('Logout Successful');
        localStorage.removeItem('user');
        localStorage.removeItem('order');
        this.$router.push('/');
      });
    }
  }
}
</script>

<style lang="scss">

.img {
  float: left;
  width: 45px;
  padding-top: 2px;
  cursor: pointer;
}

.header-title {
  font-weight: 700;
  padding-top: 17px;
  padding-left: 7px;
  font-size: 30px;
  float: left;
  cursor: pointer;
  color: #2c3c69;
}

.header-button {
  text-align: center;
  margin-top: 2px;

  .el-button {
    font-size: 20px;
  }
}
</style>