import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: 'Let\'s SSL',
        },
        component: Home
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta:{
            title:'Dashboard - Let\'s SSL',
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/Dashboard.vue')
    },
    {
        path: '/donate',
        name: 'Donate',
        meta: {
            title: 'Donate - Let\'s SSL',
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/Donate.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            title: 'Login - Let\'s SSL',
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue')
    },
    {
        path: '/resetPassword',
        name: 'ResetPassword',
        meta: {
            title: 'ResetPassword - Let\'s SSL',
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/ResetPassword.vue')
    },
    {
        path: '/addCert',
        name: 'AddCert',
        meta: {
            title: 'AddCert - Let\'s SSL',
        },
        component: () => import(/* webpackChunkName: "about" */ '../components/AddCert.vue')
    },
]

const router = new VueRouter({
    routes
})

export default router
