<template>
  <div class="footer-content">
    <el-divider></el-divider>
    <div style="margin-bottom: 20px">Let's Encrypt Contact</div>
    <el-row type="flex">
      <el-col :lg="{span:3,offset:9}" :xs="{span:7,offset:5}">
        <img src="../assets/github.png" alt="github-logo" @click="goGithub"><a class="text" @click="goGithub">Github</a>
      </el-col>
      <el-col :lg="{span:2,offset:1}" :xs="{span:7,offset:2}">
        <img src="../assets/Twitter.png" alt="twitter-logo" @click="goTwitter"><a class="text" @click="goTwitter" style="padding-left: 4px">Twitter</a>
      </el-col>
    </el-row>
    <div style="margin: 20px 0">Our Website Contact: <a href="mailto:service@letsssl.com">service@letsssl.com</a></div>
    <div style="height: 20px"></div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    goGithub() {
      window.open("https://github.com/letsencrypt")
    },
    goTwitter() {
      window.open("https://twitter.com/letsencrypt")
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-content {
  text-align: center;
  margin-top: 20px;

  a {
    cursor: pointer;
    font-weight: 300;
    font-size: 20px;
  }

  img {
    height: 17px;
    cursor: pointer;
    padding-right: 3px;
  }

  .text{
    padding-top: 5px;
  }
}
</style>